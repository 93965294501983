import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";


// todo: implement
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  onOpen() {
    this.document.body.classList.add('modal-open')
  }

  onClose() {
    this.document.body.classList.remove('modal-open')
  }

}
